//
// Error 3
//

// Initialization of global variables, mixins and functions
@import "../../init";

.my-active-offers {
	
	.title{
		color: black;
		font-weight: bold;
		font-size: 4rem;
	}
	.nav.nav-tabs{
		background-color: white;
		width: max-content;
		padding: 5px;
		border-radius: 30px;
	}
	.nav-link{
		font-weight: 600;
		color: $dark-blue !important;
		border-radius: 30px !important;
		border: none !important;
	}
	.nav-item{
		border-radius: 30px !important;
		border: none;
	}
	a.nav-link.active {
		background-color: $yellow !important;
		color: $dark-blue !important;
		border: none;
	}
	.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus{
		border-radius: 30px;
		background-color: rgba($color: #f8f8f8, $alpha: .8);
	}
	.input-home{
		width: 100%;
		font-size: 18px;
		padding: 25px 30px;
		border: none;
		outline: none;
	}
	.input-btn{
		align-self: center;
	}
	.recharge-tab{
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	}
	.more-details{
		color:$primary-blue;
		padding-top: 3rem;
		font-weight: 600;
	}

	.head-title {
		padding: 12px 10px 0px 18px;
		color: black;
		font-size: 1.5rem;
		font-weight: 600;
	}

	.card-header {
		padding: 2rem 2rem;
		border-bottom: none;
	}

	.offer-name {
		font-size: 1.3rem;
	}
	.offer-price {
		font-size: 1rem;
	}

	.rating {
		color: $dark-blue;
		font-size: 1.3rem;
	}

	.offer-exp {
		color: $dark-blue;
		font-weight: 400;
	}
	.auto-renewal {
		color: $dark-blue;
		font-weight: 600;
		font-size: 1.1rem;
		text-align: right;
	}

	.upper-title {
		font-size: 1.3rem;
	}

	.lower-title {
		color: $dark-blue;
		font-weight: 600;
		font-size: 0.9rem;
		float: right;
	}

	.progress {
		height: 0.3rem;
	}
}

// Desktop view


// Desktop view
@include media-breakpoint-up(lg) {
	.padding-container{
		padding: 50px 75px;
	}
}

// Tablet and Mobile mode
@include media-breakpoint-between(lg, xl) {
	.padding-container{
		padding: 50px 75px;
	}
}

// Tablet mode
@include media-breakpoint-down(md) {
	.padding-container{
		padding: 50px 75px;
	}
}

// Mobile mode
@include media-breakpoint-down(xs) {
	
	.padding-container{
		padding: 30px 30px;
	}
}
//
// Error 3
//

// Initialization of global variables, mixins and functions
@import "../../init";

	.dashboard{
		.row{
			margin-right: -12.5px;
    margin-left: -12.5px;
		}
	.account-info{
	    // margin-right: 7px;
		// margin-left: 0px;
		// width: 182px;
		border-radius: 7px;
		background-color: white;
		padding: 15px 10px;
	}
		.upper-title{
		text-align: left;
		color: #898B8E;
		font-size: 1.2rem;
		}
		.lower-title{
		text-align: left;
			font-weight: 500;
			font-size: 1.1rem;
		}
		.table thead th, .table thead td {
			font-size: 1.1rem;
		}
		.table tr, .table td {
			font-size: 1.1rem;
		}
	
}
// Desktop view
@include media-breakpoint-up(lg) {
}

// Tablet and Mobile mode
@include media-breakpoint-between(lg, xl) {
}

// Tablet mode
@include media-breakpoint-down(md) {
}

// Mobile mode
@include media-breakpoint-down(xs) {
}
//
// Error 3
//

// Initialization of global variables, mixins and functions
@import "../../init";

.topup-my-account {
	.row{
		display: flex;
	}
	.box {
		background-color: white;
		border-radius: 5px;
		text-align: center;
		padding: 25px;
	}

	.upper {
		margin-bottom: 0;
		color: $dark-blue;
	}

	.lower {
		font-weight: 700;
		font-size: 1.3rem;
		color: $dark-blue;
	}
	.offer{
		border-radius: 5px;
		background-color: white;
		padding: 25px;
		margin-right: -12.5px;
		margin-left: -12.5px;
	}
}

// Desktop view
@include media-breakpoint-up(lg) {}

// Tablet and Mobile mode
@include media-breakpoint-between(lg, xl) {}

// Tablet mode
@include media-breakpoint-down(md) {}

// Mobile mode
@include media-breakpoint-down(xs) {}
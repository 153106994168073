//
// Error 3
//

// Initialization of global variables, mixins and functions
@import "../../init";
.my-account{
	.navi .navi-item .navi-link.active{
		border-bottom: 2px solid $yellow;
		border-radius: 0px;
		background-color: white;
	}
	.navi .navi-item .navi-link.active .navi-text{
		color: $dark-blue;
		font-weight: 600;
	}
	.navi .navi-item .navi-link .navi-text{
		color: #898B8E;
		font-weight: 600;
	}
	.account-name{
		font-size: 2rem;
		color: $dark-blue;
	}
	.account-number{
		font-size: 1.1rem;
		color: $dark-blue;
	}
	.account-info{
	    margin-right: 7px;
		margin-left: 0px;
		width: 182px;
		border-radius: 10px;
		border: 2px dashed rgba(137, 139, 142, 0.3);
		padding: 6px;
	}
		.upper-title{
		text-align: left;
		color: #898B8E;
		font-size: 1rem;
		}
		.lower-title{
		text-align: left;
			font-weight: 500;
			font-size: 1.1rem;
		}
		.form-group label{
			font-weight: 600;
			color: $dark-blue;
		}
		.form-control.form-control-solid{
			background-color: rgba(248, 248, 248, 1) !important;
		}
		.MuiInputBase-input::before{
			border-bottom: none !important;
		}
		.MuiInput-underline:focus:not(.Mui-focused):before, .MuiInput-underline:active:not(.Mui-focused):before, .MuiInput-underline:hover:not(.Mui-disabled):before{
			border-bottom: none;
		}
		
		.password-input{
			background-color: #f8f8f8 !important;
			border-color: #F3F6F9;
			border-bottom: none;
			color: #3F4254;
			transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
			height: calc(1.5em + 1.65rem + 2px);
			padding: 0.825rem 1.42rem;
			font-size: 1.08rem;
			line-height: 1.5;
			border-radius: 0.42rem;
		}
		.form-group label {
			font-size: 1.1rem;
		}
		.swi-acc{
			padding-left: 10px;
			font-size: 1.2rem;
			color: $primary-blue;
		}

}
// Desktop view
@include media-breakpoint-up(lg) {
}

// Tablet and Mobile mode
@include media-breakpoint-between(lg, xl) {
}

// Tablet mode
@include media-breakpoint-down(md) {
}

// Mobile mode
@include media-breakpoint-down(xs) {
}
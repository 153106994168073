//
// Error 3
//

// Initialization of global variables, mixins and functions
@import "../../init";

.support {
	.head-title {
		padding: 0px 15px 15px 15px;
		color: black;
		font-size: 1.5rem;
		font-weight: 500;
	}
	.header-title {
		padding: 0px 15px 15px 15px;
		color: black;
		font-size: 3rem;
		font-weight: 700;
	}
	.card-img, .card-img-top{
		height: 90px;
		width: 90px;
	}
	.db{
		color: $primary-blue;
	}
	.card-text{
		min-height: 40px;
	}
}

// Desktop view
@include media-breakpoint-up(lg) {}

// Tablet and Mobile mode
@include media-breakpoint-between(lg, xl) {}

// Tablet mode
@include media-breakpoint-down(md) {}

// Mobile mode
@include media-breakpoint-down(xs) {}